import { Box, Center } from '@chakra-ui/react'
import { graphql } from 'gatsby'
import React from 'react'
import { ContentButton, SectionBlock } from 'starterUiComponents'
import './PromoBannerBlockStyle.css'

import { getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'

// import { convertToBgImage } from 'gbimage-bridge'
// import BackgroundImage from 'gatsby-background-image'

export const fragment = graphql`
  fragment promoBannerBlockFragment on WpPage_Flexlayouts_FlexibleLayouts_PromoBannerBlock {
    bannertitle
    bannersubtitle
    bannerContent
    cssclass
    anchor
    bannerbutton {
      target
      title
      url
    }
    bannerimage {
      altText
      localFile {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 95
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  }
`
export const PromoBannerBlock = ({
  bannertitle,
  bannersubtitle,
  bannerbutton,
  bannerContent,
  cssclass,
  anchor,
  bannerimage,
  ...props
}) => {
  const image = getImage(bannerimage.localFile.childImageSharp.gatsbyImageData)
  const backgroundFluidImageStack = [image].reverse()
  return (
    <SectionBlock
      id="section1"
      anchor={anchor}
      sx={{
        '.container': { maxWidth: '100%', p: 0 },
      }}
      {...props}
      cssclass=""
    >
      <BgImage
        image={backgroundFluidImageStack}
        className="py-10 md:py-20 px-5 md:px-10 lg:px-0"
      >
        <div className="slide-container">
          <Center className="text-center text-white">
            <Box maxWidth={960}>
              <Box
                fontWeight={300}
                className="mb-4 leading-normal uppercase font-heading text-yellow text-m sm:text-l md:mb-0"
              >
                {bannertitle}
              </Box>

              <h2 className="mb-4 leading-tight uppercase md:mb-2 text-xl sm:text-xxl">
                {bannersubtitle}
              </h2>

              <div className="text-center mb-6 leading-normal md:max-h-full overflow-hidden max-width-800">
                {bannerContent}
              </div>

              <Center>
                <ContentButton
                  variant="outlineWhite"
                  button={bannerbutton}
                  alignItems="center"
                />
              </Center>
            </Box>
          </Center>
        </div>
      </BgImage>
    </SectionBlock>
  )
}
