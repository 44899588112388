import { Select } from '@chakra-ui/react'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

export const BulletinsCatSelect = ({
  setCategory,
  selectedCategory,
  setDisplayCategoryName,
}) => {
  const data = useStaticQuery(graphql`
    query {
      allMysqlBulletins(
        filter: {
          usetemplate: { in: "intermenu" }
          appearsin: { in: "BULLETINMENU" }
        }
        sort: { fields: displaytextlink, order: ASC }
      ) {
        nodes {
          id
          displaytextlink
          disporder
          calledby
          displaytextheader
        }
      }
    }
  `)

  const { nodes } = data.allMysqlBulletins
  const cats = nodes.filter(
    (item) =>
      item.displaytextlink !== 'QUICK REFERENCES' &&
      item.displaytextlink !== 'NUMERIC INDEX'
  )

  return (
    <Select
      placeholder="Please Select"
      layerStyle="input"
      onChange={(e) => {
        const selectedCategory = e.target.value
        setCategory(selectedCategory)
        const _cat = cats.find((cat) => cat.calledby === selectedCategory)
        setDisplayCategoryName(_cat.displaytextheader)
      }}
      value={selectedCategory || ''}
    >
      {cats.map((item) => (
        <option key={item.id} value={item.calledby} id={item.id} name={item.id}>
          {item.displaytextlink}
        </option>
      ))}
    </Select>
  )
}
